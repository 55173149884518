.label {
    padding: 18px 16px 19px 16px;
    text-align: left;

    span {
        color: var(--gray-900, #252525);

        /* Body/B2 */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 175% */
    }
}

.passwordInput {
    height: 75px;

    span {
        display: block;
        padding-top: 12px;
        color: var(--gray-900, #252525);

        /* Body/B2 */
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 175% */
    }
}
