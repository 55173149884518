.input_section {
    .title {
        margin-bottom: 17px;
    }

    width: 100% !important;

    .ant_select_selector {
        width: 100% !important;
    }

    [class='ant-select'] {
        width: 100% !important;
    }

    [class='ant-col'] {
        display: flex !important;
        align-items: center !important;
    }

    [class='ant-divider-horizontal'] {
        margin: 0 !important;
    }
}

.container {
    padding: 64px 265px;
}
