#header {
    background-color: var(--gray-white);
    height: 100px;
    width: 1290px;
    border-bottom: 1px solid var(--gray-300);
    margin: 0 auto;

    .container {
        background-color: var(--gray-white);

        gap: 0;

        display: flex;
        align-items: center;
        height: 100px;

        .menu_wrap {
            width: 1056px;
            display: flex;
            justify-content: space-between;
        }

        .logo_wrap {
            margin-right: 35px;

            .h8 {
                margin-top: 10px;
                display: block;
            }
        }

        .menu {
            display: flex;

            .menu-btn {
                padding: 0;

                .header_menu {
                    display: flex;
                    height: 100px;
                    flex-direction: column;
                    justify-content: center;
                    flex-shrink: 0;
                    white-space: nowrap;

                    padding: 10px 15px;
                    gap: 10px;

                    &:hover {
                        background: var(--gray-50);
                        height: 100px;
                    }
                }

                &.active {
                    background: var(--gray-900);
                    color: var(--gray-white, #fff);

                    overflow: visible;

                    .slim_text3 {
                        color: var(--gray-white) !important;
                    }

                    a {
                        color: var(--gray-white) !important;
                        height: -webkit-fill-available;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .slim_text3,.slim_text4 {
                            color: var(--gray-white) !important;
                        }
                    }

                    .header_menu {
                        background: var(--gray-900);
                        overflow: visible;
                        height: 100px;

                        a {
                            color: var(--gray-white) !important;
                        }
                    }
                }
            }
        }

        .login {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-left: 27px;

            // .avatar {
            //   display: flex;
            //   align-items: center;
            //   padding-right: 4px;
            // }

            .info {
                display: flex;
                align-items: center;
                color: var(--gray-600, #7b7b7b);

                .sub_title2 {
                    color: var(--blue-900, #024383);
                    padding-right: 12px;
                    white-space: nowrap;
                    .body2 {
                        margin: 0;
                    }
                }

                .gray {
                    color: var(--gray-600, #7b7b7b);
                }
            }

            button,
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                span {
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 26px;
                    font-size: 14px;
                }
            }

            .password-change {
                position: relative;
                span {
                    font-weight: 700;
                }

                ::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 1px;
                    height: 58px;
                    background: var(--gray-300);
                    margin: 0 -16px;
                }
            }
        }
    }
}

.h92 {
    height: 92% !important;
}
