.container {
    width: 1290px;
    margin-bottom: 48px;
}

.card {
    width: 638px;
    height: 232px;
    flex-shrink: 0;
    position: relative;

    border-radius: 4px;
    border: 1px solid var(--gray-300, #e5e5e5);
    background: var(--gray-white, #fff);
    cursor: pointer;

    padding: 32px 40px;

    &:hover {
        border-radius: 4px;
        border: 1px solid var(--gray-900, #252525);
        background: var(--gray-white, #fff);

        /* Pop */
        box-shadow:
            0px 4px 6px -2px rgba(16, 24, 40, 0.05),
            0px 12px 16px -4px rgba(16, 24, 40, 0.1);
    }

    button {
        text-align: left;
    }
}
