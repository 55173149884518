.box_md {
    width: 790px;
}
.asterisk {
    color: red;
}
.box_lg {
    width: 1192px;
}

.border_style {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.border_btm {
    border-bottom: 1px solid #e5e5e5;
}

.row_item {
    border-bottom: 1px solid #e5e5e5;
    height: 72px;
    &:last-child {
        border-bottom: none;
    }
}

.row_item_bottom {
    border-bottom: 1px solid #e5e5e5;
    //height: 72px;
    &:last-child {
        border-bottom: none;
    }
}

.banner {
    //width: 120rem;
    position: relative;
    width: 100%;
    height: 15.75rem;
    background-color: var(--green-300, #43b89b);

    .title {
        width: 1290px;
        margin: 0 auto;
        height: 100%;
        color: var(--gray-white, #fff);
        display: flex;
        align-items: center;
    }

    .icon {
        position: absolute;
        top: 16px;
        right: 180px;
    }
}

.mb15 {
    margin-bottom: 15px;
}

.area {
    padding: 64px 49px 0 49px;
}

.mt {
    margin-top: 18px;
}
.mb {
    margin-bottom: 36px;
    &_lg {
        margin-bottom: 48px;
    }
    &_60 {
        margin-bottom: 60px;
    }
    &_xl {
        margin-bottom: 80px;
    }
}

.spin_wrap {
    display: flex;
    justify-content: center;
    height: 500px;
    align-items: center;
}

.ant_divider {
    margin: 80px 0;
}

.text_gray {
    color: #7a7a7a;
}
.text_dark_gray {
    color: #252525;
}

.padding {
    &_top {
        padding-top: 9px;
    }
    &_right {
        padding-right: 20px;
    }
    &_left {
        &_large {
            padding-left: 40px;
        }
    }
    &_x {
        padding-left: 9px;
        padding-right: 9px;
        &_medium {
            padding-left: 24px;
            padding-right: 24px;
        }

        &_large {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    &_y {
        &_xs {
            padding-top: 11px;
            padding-bottom: 9px;
        }
        &_small {
            padding-top: 13px;
            padding-bottom: 13px;
        }
        &_medium {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        &_large {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &_xlarge {
            padding-bottom: 150px;
        }
    }
}

.field {
    padding: 0 40px 20px 40px;
    .content {
        border-bottom: 1px solid var(--gray-300);
        padding-bottom: 20px;
    }
}

.success {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 36px;

    padding-bottom: 150px;
    padding-top: 50px;

    .icon {
        display: flex;
        justify-content: center;
    }
}

.profile {
    [class='ant-form-item'] {
        margin-bottom: 4px;
        height: 51px;
    }
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    color: #7a7a7a;
    &_align {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
    }

    &_edit {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-bottom: 12px;

        &.start {
            align-items: start;
        }

        .user {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }
}
.address_form {
    input {
        margin-bottom: 4px;
    }
}

.date_picker {
    margin-top: 9px;
    height: 50px;
    width: 179px;
}

.btn {
    &_add {
        border: 0.5px solid #e5e5e5;
        border-radius: 0;
        background: #f7f7f7;
        margin: 0 auto;
        width: 100%;
        height: 72px;
        :hover {
            cursor: pointer;
        }
    }
}

.title {
    &_sm {
        display: block;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    &_lg {
        display: block;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 48px;
    }
}

.m_auto {
    margin: 0 auto;
}

.basic_font {
    font-size: 16px;
    color: #7b7b7b;

    .remark {
        text-align: center;
        width: 100px;
    }
    .remark_text {
        height: 50px;
    }
}

.upload {
    span {
        display: block;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0px;
    }
}

.not_found_page_wrap {
    align-items: center;

    .not_found_page_top {
        padding: 50px;
        .sub_title1 {
            font-weight: 700;
        }

        .not_found_page_icon {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;

            &:before {
                background-image: url(../../assets/Icons/svg/Re.svg);
                background-repeat: no-repeat;
                width: 114px;
                height: 96px;
                content: '';
                display: block;
            }
        }

        .not_found_page_title {
            display: flex;
            justify-content: center;
        }
    }
}

.upload_icon {
    width: 100px;
    height: 100px;

    background-image: url('../Icons/svg/upload.svg');
    background-repeat: no-repeat;
    background-position: 50%;
}

.upload_icon_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.added_file_area {
    border-radius: 4px;
    border: 1px solid var(--gray-300, #e5e5e5);
    background: var(--gray-white, #fff);

    margin-top: 12px;
    padding: 18px 28px;

    display: flex;
    justify-content: space-between;
}

.delete_btn {
    background-image: url('../Icons/svg/gray_trash.svg');
    background-repeat: no-repeat;

    width: 18px;
    height: 20px;
    display: block;
}

.ant_select_selector {
    min-width: 150px;

    &::after {
        content: '';
        display: block;
        margin-top: 20px;
        margin-left: 2px;
        margin-right: 14px;
        width: 1px;
        height: 38px;
        background: var(--gray-white, #fff);
        opacity: 0.48;
        position: absolute;
        top: 0;
        right: -14px;
    }

    [class='ant-select-selection-item'] {
        color: var(--gray-600);

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
    }
}

.col {
    .date_picker {
        margin-top: 9px;
        height: 53px;
        width: 100%;

        &.ant-picker-focused {
            height: auto !important;
        }
    }
    input {
        height: 99%;
    }
}

.ant_input {
    height: 53px;
    width: 220px;
}

.text {
    font-size: 16px;
    padding: 12px;
}

// not found

//.not_found_page_icon {
//    display: flex;
//    justify-content: center;
//    margin-bottom: 36px;
//
//    &:before {
//        background-image: url(../../../assets/Icons/svg/Re.svg);
//        background-repeat: no-repeat;
//        width: 114px;
//        height: 96px;
//        content: "";
//        display: block;
//    }
//}

.not_found_container {
    padding: 20px;
}

.not_found_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    //margin-bottom: 36px;

    &:before {
        background-size: 65px;
        background-image: url(../../assets/Icons/svg/Re.svg);
        background-repeat: no-repeat;
        //background-size: 80px;
        width: 81px;
        height: 68px;
        content: '';
        display: block;
    }
}

.list {
    display: flex;
    flex-direction: column;
    //gap: 16px;
    border: 1px solid #ddd;
    border-radius: 2px;
    border-bottom: none;
}

.header {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #ddd;
    padding: 26px 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background-color: #f7f7f7;

    [class='ant-col'] {
        font-size: 16px;
    }
}
.col {
    text-align: center;
}

.btno {
    border-top: none;
}

.bbno {
    border-bottom: none;
}
